export function GROUP_FILTERS() {
    return [
        {
            value: 'all',
            label: 'group.all_items',
        },
        {
            value: 'single',
            label: 'group.single_items',
            disabled: true,
        },
        // {
        //   value: 'bundle',
        //   label: 'Bundles',
        // },
    ];
}
