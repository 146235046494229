<template>
    <div class="accounthidden">
        AccountHidden
    </div>
</template>

<script>
export default {
    name: 'AccountHidden',
};
</script>

<style></style>
