<template>
    <div>
        <p-g-my-nfts-list></p-g-my-nfts-list>
    </div>
</template>

<script>
import PGMyNftsList from '../../components/PGMyNftsList/PGMyNftsList';

export default {
    name: 'PGMyNfts',

    components: {
        PGMyNftsList,
    },
};
</script>
