<template>
    <div class="collectionregister">
        <collection-register-form />
    </div>
</template>

<script>
import CollectionRegisterForm from '@/modules/collections/components/CollectionRegisterForm/CollectionRegisterForm.vue';
export default {
    name: 'CollectionRegister',

    components: { CollectionRegisterForm },
};
</script>
